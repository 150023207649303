.block {
    margin-bottom: 0;

    & + & {
        margin-top: 3rem;

        @include mq($from: md) {
            margin-top: 5rem;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
}

.block--media {
    img {
        width: 100%;
    }

    figcaption {
        margin-top: 0.5rem;
        font-size: $font-size-small;
        font-style: italic;

        @include mq($until: sm) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

// breaks block element out of container by two columns
.block--expand {
    margin-left: -#{map_get($layout-gutter-widths, xs)};
    margin-right: -#{map_get($layout-gutter-widths, xs)};

    @include mq($from: sm) {
        margin-left: -#{map_get($layout-gutter-widths, sm)};
        margin-right: -#{map_get($layout-gutter-widths, sm)};
    }

    @include mq($from: md) {
        margin-left: -(percentage(1 / $layout-items));
        margin-right: -(percentage(1 / $layout-items));
    }
}
