.chapter-header {
    display: flex;
    justify-content: center;
    background-color: $brand-primary;
    color: #fff;
}

.chapter-header__content {
    display: flex;
    align-items: flex-start;
    padding: 10rem 0 4rem;

    @include mq($from: $chapter-sidebar-breakpoint) {
        padding: 5rem 0;
    }

    @include mq($from: xl) {
        padding: 8rem 0;
    }
}

.chapter-header__rank {
    display: block;
    margin-right: 10px;
    line-height: 1.2;
    font-weight: $heading-font-weight;
    font-size: 1.6rem;

    @include mq($from: sm) {
        margin-right: 20px;
        font-size: 8rem;
        line-height: 1;
    }

    @include mq($from: md) {
        margin-top: -20px;
        font-size: 10rem;
    }
}

.chapter-header__text {
    p {
        margin: 0;
    }
}
