.menu-icon {
    position: relative;
    width: 25px;
    height: 21px;
    margin: 20px;
    border: none;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    appearance: none;
    background: none;

    &:focus {
        outline: none;
    }

    span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        transform: rotate(0deg);
        opacity: 1;
        transition: transform 0.25s ease-in-out, color 0.25s ease-in-out;
        background: $brand-primary;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 9px;
        }

        &:nth-child(4) {
            top: 18px;
        }

        &:nth-child(1),
        &:nth-child(4) {
            .chapter-sidebar.is-active & {
                top: 18px;
                left: 50%;
                width: 0;
            }
        }

        &:nth-child(2) {
            .chapter-sidebar.is-active & {
                transform: rotate(45deg);
            }
        }

        &:nth-child(3) {
            .chapter-sidebar.is-active & {
                transform: rotate(-45deg);
            }
        }
    }
}
