$button-default-border-radius: 50px !default;
$button-primary-bg: $brand-secondary !default;
$button-primary-color: #fff !default;
$button-padding: 0.8rem 2.5rem !default;
$button-padding-small: 0.6rem 1.5rem !default;
$button-font-family: inherit !default;

.button {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: $button-padding;
    border: 1px solid transparent;
    border-radius: $button-default-border-radius;
    background-color: transparent;
    font-family: $button-font-family;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    font-weight: 700;
    transition: 0.2s background;

    &.is-locked {
        cursor: not-allowed;
    }

    &.is-loading {
        position: relative;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: -30px;
            width: 20px;
            height: 20px;
            margin-left: -10px;
            margin-top: -10px;
            border: 2px solid transparent;
            border-radius: 50%;
            animation: spin 1.5s linear infinite;
        }

        &:before {
            border-color: $brand-primary;
            opacity: 0.25;
        }

        &:after {
            border-top-color: $brand-primary;
        }
    }

    &.is-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.button--primary {
    @include button-variant($button-primary-color, $button-primary-bg);

    &.is-locked {
        background-color: mix(#fff, $button-primary-bg, 25%);
    }

    &.is-loading {
        &:before {
            border-color: $button-primary-bg;
            opacity: 0.25;
        }

        &:after {
            border-top-color: $button-primary-bg;
        }
    }
}

.button--link {
    padding-left: 0;
    padding-right: 0;
    color: $link-color;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.button--small {
    padding: $button-padding-small;
    font-size: 0.85rem;
    font-weight: 300;
}
