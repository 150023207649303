.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1 0 auto;
}

html {
    overflow: auto;
}

@include mq($from: xl) {
    html {
        font-size: 125%;
    }
}
