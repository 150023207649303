@include mq($until: sm) {
    h1 {
        font-size: $font-size-h1-mobile;
    }

    h2 {
        font-size: $font-size-h2-mobile;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        word-break: break-word;
    }
}

strong {
    font-weight: 500;
}
