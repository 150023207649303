$footer-bg: #f9f9f9 !default;

.footer {
    position: relative;
    z-index: $zindex-footer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: $footer-bg;
    text-align: center;
}

.footer__item {
    display: inline-flex;
    align-items: center;
}

.footer__logo {
    // use 2/3 of regular logo size
    width: (map_get($logo-dimensions, width) / 3) * 2;
    height: (map_get($logo-dimensions, height) / 3) * 2;
    margin-bottom: 1rem;

    // create exception for centraal-beheer as the logo has a different shape
    @if ($theme == 'centraal-beheer') {
        width: 71px;
        height: 50px;
    }
}

@include mq($from: md) {
    .footer {
        flex-direction: row;
    }

    .footer__logo {
        margin-bottom: 0;
    }

    .footer__item {
        padding: 0 1.5rem;
    }
}

@include mq($from: lg) {
    .footer__item {
        padding: 0 2.5rem;
    }
}
