.wysiwyg {
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 0.5em;
        font-size: $font-size-h4;
    }
}
