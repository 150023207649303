.chapter-sidebar {
    position: fixed;
    z-index: $zindex-chapter-sidebar;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: #fff;

    &.is-active {
        height: 100vh;
        overflow-y: auto;
    }

    @include mq($from: $chapter-sidebar-breakpoint) {
        position: static;
        flex-direction: row;
        max-width: 20%;

        &,
        &.is-active {
            height: auto;
        }
    }
}

.chapter-sidebar__header {
    display: flex;
    flex: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 40px rgba(#000, 0.1);

    @include mq($from: $chapter-sidebar-breakpoint) {
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        justify-content: center;
        width: 20%;
        padding: 40px;
        box-shadow: none;
        background-color: transparent;
    }
}

.chapter-sidebar__content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px;

    @include mq($from: $chapter-sidebar-breakpoint) {
        position: fixed;
        z-index: 4;
        top: 50%;
        left: 0;
        width: 100%;
        max-width: 20%;
        transform: translateY(-50%);
    }
}

.chapter-sidebar__toggle {
    @include mq($from: $chapter-sidebar-breakpoint) {
        display: none;
    }
}

.chapter-sidebar__nav {
    width: 100%;
    margin-bottom: 10vh;
}

.chapter-sidebar__list {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    list-style: none;

    &.is-disabled {
        opacity: 0.5;

        .chapter-sidebar__link {
            cursor: wait;
        }
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 1rem;
        bottom: 1rem;
        transform: translateX(-50%);
    }

    &:before {
        z-index: 1;
        border-left: 2px solid $brand-primary;
    }

    &:after {
        z-index: 3;
        border-left: 2px dotted $brand-primary;
    }
}

.chapter-sidebar__list-item {
    display: flex;
    justify-content: center;

    &.is-active {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 1rem;
            left: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 100vh;
            background-color: #fff;
        }
    }
}

.chapter-sidebar__link {
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 1rem 0;
    border: 2px solid $brand-primary;
    border-radius: 50%;
    color: $brand-primary;
    background-color: #fff;
    font-weight: $heading-font-weight;
    transition: background-color 0.2s, color 0.2s;

    &:hover,
    .chapter-sidebar__list-item.is-finished & {
        background-color: $brand-primary;
        color: #fff;

        &:hover {
            border-color: mix(#000, $brand-primary, 10%);
            background-color: mix(#000, $brand-primary, 10%);
        }
    }

    .chapter-sidebar__list-item.is-active & {
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: -10px;
            right: -10px;
            bottom: -10px;
            left: -10px;
            display: block;
            border: 2px solid $brand-primary;
            border-radius: 50%;
            transform-origin: center;
            opacity: 0;
            animation: pulse-border 4s infinite;
        }

        &:after {
            animation-delay: 2s;
        }
    }
}

.chapter-sidebar__link-finished {
    position: absolute;
    right: -1.5em;
    top: 2px;
    display: block;
    width: 1em;
    height: 1em;
    background-image: svg-uri($check-icon);
    background-size: cover;
}
