.chapter {
    display: flex;
}

.chapter__content {
    position: relative;
    z-index: $zindex-chapter-content;
    flex: 1 0 0;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateY(100%);
    transition: transform 0.5s;

    &.is-initialized {
        transform: translateY(0);
    }

    &.is-updating {
        animation: is-updating 1s;

        &.is-animating-down {
            animation: is-updating--down 1s;
        }
    }
}

@keyframes is-updating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-100vh);
    }

    51% {
        opacity: 0;
    }

    52% {
        transform: translateY(100vh);
    }

    53% {
        opacity: 1;
    }

    90% {
        transform: translateY(0);
    }
}

@keyframes is-updating--down {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(100vh);
    }

    51% {
        opacity: 0;
    }

    52% {
        transform: translateY(-100vh);
    }

    53% {
        opacity: 1;
    }

    90% {
        transform: translateY(0);
    }
}

.chapter__blocks {
    margin: 3rem 0;
}

.chapter__content-exam-btn {
    margin: 2rem 0;
    text-align: center;
}

@include mq($from: $chapter-sidebar-breakpoint) {
    .chapter__blocks {
        margin: 7rem 0;
    }

    .chapter__content-exam-btn {
        display: none;
    }
}
