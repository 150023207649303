.paragraph__title {
    font-size: $font-size-h4;
}

.paragraph__button {
    margin-top: 0.75em;
    font-weight: $base-font-weight;

    &,
    &:hover,
    &:focus {
        outline: none;
    }

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 1.25em;
        height: 1.25em;
        margin-top: -3px;
        margin-right: 0.5em;
        background-image: svg-uri($plus-icon);
        background-size: cover;
    }

    &.is-expanded:before {
        background-image: svg-uri($minus-icon);
    }
}

.paragraph__description {
    margin-top: 1rem;
}
