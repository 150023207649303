$usp-bg-color: $brand-primary !default;
$usp-color: #fff !default;
$usp-border-radius: 5px !default;

.usp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    padding: 4rem 2rem 2rem;
    border-radius: $usp-border-radius;
    background-color: $usp-bg-color;
    color: $usp-color;
}

.usp__icon {
    max-width: 70px;
    margin: 0 auto;
}

.usp__title {
    justify-self: flex-end;
    margin: 1rem 0 0;
    font-size: $font-size-h4;
    font-weight: 400;
    text-align: center;
}

@include mq($from: lg) {
    .usp {
        margin-bottom: 0;
    }

    .usp__icon {
        max-width: 100px;
    }
}
