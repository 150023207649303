@mixin custom-scrollbar {
    position: relative;

    &,
    .custom-scroll,
    .outer-container,
    .inner-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    .custom-scroll {
        .positioning {
            position: static;
        }

        .custom-scrollbar {
            z-index: 4;
            top: 0;
            opacity: 0.5;
            pointer-events: all;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }

        .inner-handle {
            background-color: rgba($brand-primary, 0.9);
        }
    }
}
