.confetti-container {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    perspective: 700px;
}

.confetti {
    position: absolute;
    z-index: 1;
    top: -10px;
    border-radius: 0;

    &--animation-slow {
        animation: confetti-slow 5.25s linear 1 forwards;
    }

    &--animation-medium {
        animation: confetti-medium 3.75s linear 1 forwards;
    }

    &--animation-fast {
        animation: confetti-fast 2.25s linear 1 forwards;
    }

    &--primary {
        background: $confetti-primary-color;
    }

    &--secondary {
        background: $confetti-secondary-color;
    }
}
