$spotlight-bg-color: $brand-primary !default;
$spotlight-color: #fff !default;

.spotlight {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    padding: 120px 0;
    background-color: $spotlight-bg-color;
    color: $spotlight-color;

    @supports (object-fit: cover) {
        background-color: rgba($spotlight-bg-color, 0.9);
    }

    .wysiwyg {
        a {
            color: $spotlight-color;
            text-decoration: underline;
        }
    }
}

.spotlight__image {
    display: none;
    max-width: 300px;
}

.spotlight__bg-image {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;

    @supports (object-fit: cover) {
        display: block;
        object-fit: cover;
    }
}

@include mq($from: lg) {
    .spotlight__image {
        display: block;
    }
}
