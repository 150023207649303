.exam-progress {
    z-index: $zindex-exam-progress;
    display: flex;
    justify-content: center;
    flex: none;
    height: 100px;
    padding-top: 15px;
}

.exam-progress__dots {
    display: flex;
    justify-content: center;
    margin: 0 -10px;
}

.exam-progress__dot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    margin: 0 10px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    transition-property: width, height, background-color;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: -12px;
        left: -12px;
        right: -12px;
        bottom: -12px;
        display: block;
        border-radius: 50%;
        border: 2px solid #fff;
        opacity: 0;
        transform-origin: center;
    }

    &.is-finished {
        background-color: #fff;
    }

    &.is-active {
        position: relative;
        background-color: #fff;

        &:before,
        &:after {
            animation: pulse-border 4s infinite;
        }

        &:after {
            animation-delay: 2s;
        }
    }
}
