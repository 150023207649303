$finished-course-padding-mobile: 20px 15px;
$finished-course-padding-desktop: 40px 55px;

.finished-course {
    position: fixed;
    z-index: $zindex-finished-course;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
}

.finished-course__content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0;
}

.finished-course__title {
    display: block;
    margin-bottom: 60px;
    text-align: center;
    color: $brand-primary;
}

.finished-course__form {
    padding: $finished-course-padding-mobile;
    border-radius: 5px;
    background-color: #f9f9f9;

    @include mq($from: $finished-course-breakpoint) {
        padding: $finished-course-padding-desktop;
    }
}

.finished-course__form-header {
    text-align: center;
}

.finished-course__form-title {
    margin-bottom: 0.5em;
    font-size: $font-size-h4;
}

.finished-course__form-description {
    margin: 0;
}

.finished-course__form-fields {
    padding: 20px 0;

    @include mq($from: $finished-course-breakpoint) {
        padding: 40px 0;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.finished-course__button-wrapper {
    text-align: center;
}
