// exam colors
$exam-wrapper-bg-color:             $brand-primary !default;
$exam-rank-bg-color:                $brand-primary !default;
$exam-answer-bg-color:              rgba($brand-primary, 0.15) !default;

// exam point size
$exam-point-size-xs:                30px;
$exam-point-size:                   40px;

// exam spacings
$exam-padding-mobile:               20px 15px;
$exam-padding-desktop:              30px 45px;

// exam font sizes
$exam-title-font-size-xs:           20px;
$exam-title-font-size:              24px;
$exam-text-font-size-xs:            12px;
$exam-text-font-size:               16px;

.exam {
    position: fixed;
    z-index: $zindex-exam;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $exam-wrapper-bg-color;
}

.exam__header-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 50px;

    @include mq($from: $exam-breakpoint) {
        padding-right: 0;
    }
}

.exam__header-title {
    position: absolute;
    left: 50%;
    display: none;
    flex: none;
    transform: translateX(-50%);
    color: #fff;
    font-size: $font-size-h2;

    @include mq($from: lg) {
        display: block;
    }
}

.exam__header-exit {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-image: svg-uri($cancel-icon-white);
    background-size: 20px;
    background-repeat: no-repeat;

    @include mq($from: $exam-breakpoint) {
        position: static;
    }
}

.exam__questions {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;

    &,
    .exam__question,
    .exam__question-front,
    .exam__question-back {
        height: 425px;
        border-radius: 5px;

        @include mq($from: md) {
            height: 500px;
        }
    }

    @include mq($from: xl) {
        max-width: 750px;
    }
}

.exam__question {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: scale(1);
    opacity: 1;
    transition: 0.6s;
    font-size: $exam-text-font-size-xs;

    @include mq($from: $exam-breakpoint) {
        font-size: $exam-text-font-size;
    }

    &.is-active {
        z-index: 999 !important; // overrides JS inline styling @todo think of something better
        top: 0 !important;
        transform: scale(1) !important;
    }

    &.is-flipped {
        .exam__question-front {
            display: none;
        }

        .exam__question-back {
            display: flex;
        }
    }

    &.is-finished {
        z-index: -1;
        transform: scale(1.2) !important; // overrides JS inline styling
        opacity: 0;
        pointer-events: none;
        transition: all 0.6s ease, z-index 0s 0.6s;
    }

    .exam__question-front {
        display: flex;
        flex-direction: column;
        box-shadow: 0 10px 20px 0 rgba(#121212, 0.1);
    }

    .exam__question-back {
        display: none;
        flex-direction: column;
    }
}

@supports (transform-style: preserve-3d) {
    .exam__question {
        transform: scale(1);
        perspective: 1000px;

        &,
        &.is-active,
        &.is-flipped,
        &.is-finished {
            .exam__question-front,
            .exam__question-back {
                display: flex;
            }
        }

        &.is-finished {
            transform: scale(1.2) !important; // overrides JS inline styling
        }
    }

    .exam__question-front {
        z-index: 2;
        transform: rotateY(0);

        .exam__question.is-flipped & {
            transform: rotateY(180deg);
        }
    }

    .exam__question-back {
        transform: rotateY(-180deg);

        .exam__question.is-flipped & {
            transform: rotateY(0);
        }
    }

    .exam__question-front,
    .exam__question-back {
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;
        width: 100%;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        background-color: #fff;
        transition: transform 0.6s;
    }
}

.exam__questions-deck {
    flex: 1 1 100%;
    margin: 50px 20px;

    @include mq($from: $exam-breakpoint) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 30px 15px;
    }
}

.exam__question-explanation {
    @include custom-scrollbar;
    padding: $exam-padding-mobile;
    padding-bottom: 0;
    background-color: #f9f9f9;

    @include mq($from: $exam-breakpoint) {
        padding: $exam-padding-desktop;
        padding-bottom: 0;
    }

    p {
        margin: 0;
    }
}

.exam__question-explanation-title {
    display: flex;
    font-size: $exam-title-font-size-xs;

    &:before {
        content: '';
        display: block;
        flex: none;
        width: $exam-point-size-xs;
        height: $exam-point-size-xs;
        margin-right: 20px;
        border-radius: 50%;
        background-color: $brand-danger;
        background-image: svg-uri($cancel-icon-white);
        background-size: $exam-text-font-size-xs;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.is-correct:before {
        background-color: $brand-success;
        background-image: svg-uri($check-icon-white);
    }

    @include mq($from: $exam-breakpoint) {
        font-size: $exam-title-font-size;

        &:before {
            width: $exam-point-size;
            height: $exam-point-size;
            background-size: $exam-text-font-size;
        }
    }
}

.exam__question-header {
    padding: $exam-padding-mobile;
    border-radius: 5px 5px 0 0;
    background-color: #fff;

    @include mq($from: $exam-breakpoint) {
        padding: $exam-padding-desktop;
    }
}

.exam__question-position {
    color: #a3a3a3;
}

.exam__question-title {
    margin: 0;
    color: $brand-primary;
    font-size: $exam-title-font-size-xs;

    @include mq($from: $exam-breakpoint) {
        font-size: $exam-title-font-size;
    }
}

.exam__question-answers-wrapper {
    @include custom-scrollbar;
    padding: $exam-padding-mobile;
    border-radius: 0 0 5px 5px;
    background-color: #f9f9f9;

    @include mq($from: $exam-breakpoint) {
        padding: $exam-padding-desktop;
    }

    // PREV-123 fixes issue when custom scrollbar item is positioned above active item
    .exam__question.is-flipped & {
        .inner-container {
            overflow: hidden;
        }

        .positioning {
            display: none;
        }
    }
}

.exam__question-answers {
    margin: -5px 0;
}

.exam__question-answer {
    display: flex;
    align-items: flex-start;
    padding: 5px 0;
    cursor: default;

    &.is-clickable {
        cursor: pointer;
    }
}

.exam__question-answer-rank {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    width: $exam-point-size-xs;
    height: $exam-point-size-xs;
    margin: 0 10px 0 0;
    border-radius: 50%;
    color: #fff;
    background-color: $exam-rank-bg-color;
    text-transform: uppercase;
    font-weight: $heading-font-weight;

    @include mq($from: $exam-breakpoint) {
        width: $exam-point-size;
        height: $exam-point-size;
    }
}

.exam__question-answer-text {
    flex: 1 1 100%;
    padding: 10px 30px 10px 15px;
    border-radius: 10px;
    background-color: $exam-answer-bg-color;
}

.exam__question-button-wrapper {
    padding: $exam-padding-mobile;
    border-radius: 0 0 5px 5px;
    text-align: center;
    background-color: #f9f9f9;

    @include mq($from: $exam-breakpoint) {
        padding: $exam-padding-desktop;
    }
}
