$loader-color: $brand-primary !default;
$loader-size: 2rem !default;
$loader-margin: 2rem auto !default;

.loader {
    position: relative;
    width: $loader-size;
    height: $loader-size;
    margin: $loader-margin;

    .loader__circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $loader-color;
        opacity: 0.6;
        animation: doubleBounce 2s infinite ease-in-out;
    }

    .loader__circle--2 {
        animation-delay: -1s;
    }
}

.loader-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; // IE11 needs the parent to have flex-direction: column, when parent has position: fixed|absolute.

    .loader {
        transform: translateY(-50%);
    }
}

@keyframes doubleBounce {
    0%,
    100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}
