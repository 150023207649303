/* stylelint-disable length-zero-no-unit */
$breakpoints: (
    xs: 0px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1440px,
    xxl: 1780px
) !default;
/* stylelint-enable */

// components breakpoints
$chapter-sidebar-breakpoint:      lg !default;
$exam-breakpoint:                 md !default;
$finished-course-breakpoint:      md !default;
