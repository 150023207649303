.header {
    position: relative;
    flex: none;
    margin: 20px 0 0;
}

.header--home {
    position: absolute;
    z-index: $zindex-header;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
}

.header__logo {
    vertical-align: middle;
    width: map_get($logo-dimensions, width);
    height: map_get($logo-dimensions, height);

    @include mq($until: $chapter-sidebar-breakpoint) {
        @if ($theme == 'centraal-beheer') {
            width: auto;
            height: 50px;
        }
    }
}

@include mq($from: sm) {
    .header {
        margin: 40px 0 0;
    }
}
